import {Linking} from 'react-native';

export function unixTimestampToSeconds(unixTimestamp: number) {
  const date = new Date(unixTimestamp * 1000);
  return date.getSeconds();
}

export function navigateUrl(url: string, item: any) {
  Linking.openURL(`${url}${JSON.stringify(item)}`);
}
