import * as React from 'react';
import {
  StyleSheet,
  View,
  ImageBackground,
  Image,
  TouchableOpacity,
} from 'react-native';
import Text from '@/components/basic/text';
import {
  FontFamily,
  FontSize,
  Color,
  Padding,
  Border,
} from '../card.common.styles';
import {useEffect, useState} from 'react';
import {designToDp, unixTimestampToSeconds} from '@utils';
// import {FontFamily} from '../card.common.styles';

interface BackTimeCardData {
  winAmount: number;
  sellAmount: number;
  id: number;
  pickLogo: string | undefined;
  pickBackImg: string;
  pickName: string;
  drawTime: number;
  src: string;
  price: number;
}
interface BackTimeCardProps {
  data: BackTimeCardData;
  onPress?: (data: BackTimeCardData) => void;
}

const CardBackTime = (props: BackTimeCardProps) => {
  const {data, onPress} = props;

  const initTime = unixTimestampToSeconds(data?.drawTime);
  const [timer, setTimer] = useState<number>(initTime);

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer >= 0) {
        setTimer(_timer => _timer - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let hours = Math.floor(timer / 3600);
  let minutes = Math.floor((timer % 3600) / 60);
  let seconds = timer % 60;
  if (hours < 0) {
    hours = 0;
  }
  if (minutes < 0) {
    minutes = 0;
  }
  if (seconds <= 0) {
    seconds = 0;
  }
  useEffect(() => {
    if (seconds === 1) {
      setTimeout(() => {
        setTimer(initTime);
      }, 1000);
    }
  }, [initTime, seconds]);

  return (
    <TouchableOpacity
      onPress={() => {
        onPress && onPress(data);
      }}>
      <View style={styles.card3digit}>
        <View style={styles.frameParent}>
          <View style={styles.frameChild} />
          <ImageBackground
            source={{uri: data?.pickBackImg}}
            style={styles.backImg}>
            <Image style={styles.backIcon} source={{uri: data.pickLogo}} />
            <View style={styles.fenlei}>
              <Text style={styles.kuberLottery}>{data.pickName}</Text>
            </View>
            {/* <View>
              <Text style={styles.winText} fontFamily="fontInterBold">
                WIN PRIZE
              </Text>
            </View> */}
            <View>
              <Text fontFamily="fontInterBold" style={styles.win}>
                ₹{data.winAmount}
              </Text>
            </View>
            <View style={styles.timeForNextBookingParent}>
              <Text style={styles.timeForNext}>Time for Next booking</Text>
              <View style={styles.frameWrapper}>
                <View style={styles.frameGroupFlexBox}>
                  <View style={styles.wrapperFlexBox}>
                    <Text style={styles.textTypo}>
                      {hours >= 10 ? hours : '0' + hours}
                    </Text>
                  </View>
                  <Text style={[styles.text1, styles.textTypo]}>:</Text>
                  <View style={[styles.container, styles.wrapperFlexBox]}>
                    <Text style={styles.textTypo}>
                      {minutes >= 10 ? minutes : '0' + minutes}
                    </Text>
                  </View>
                  <Text style={[styles.text1, styles.textTypo]}>:</Text>
                  <View style={[styles.container, styles.wrapperFlexBox]}>
                    <Text style={[styles.textTypo]}>
                      {seconds >= 10 ? seconds : '0' + seconds}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </ImageBackground>
        </View>

        <View style={[styles.card3digitInner, styles.frameGroupFlexBox]}>
          <View style={styles.parent}>
            <Text style={styles.text5}>₹{data?.sellAmount}</Text>
            <Text style={styles.ticket}>/Ticket</Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  winText: {
    position: 'absolute',
    top: 30,
    left: 10,
    fontSize: 9,
  },
  win: {
    position: 'absolute',
    top: 30,
    left: 10,
    width: '60%',
    height: '20%',
    // fontFamily: 'Anybody',
    fontSize: 18,
    fontWeight: '700',
    color: '#FFF8B8',
    transform: [{skewX: '-10deg'}],
  },
  backIcon: {
    position: 'absolute',
    top: 5,
    right: 0,
    width: '50%',
    height: '80%',
  },
  backImg: {
    flex: 1,
    resizeMode: 'cover',
  },
  textTypo: {
    textAlign: 'right',
    fontFamily: FontFamily.interBold,
    fontSize: FontSize.size_4xs,
    color: Color.white,
    fontWeight: '700',
    letterSpacing: -0.2,
  },
  wrapperFlexBox: {
    justifyContent: 'center',
    padding: Padding.p_11xs,
    backgroundColor: Color.color2,
    alignItems: 'center',
    borderRadius: Border.br_11xs,
  },
  frameGroupFlexBox: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  frameChild: {
    top: 0,
    left: 0,
    backgroundColor: Color.whitesmoke_100,
    position: 'absolute',
    height: designToDp(114),
    width: designToDp(172),
    overflow: 'hidden',
  },
  kuberLottery: {
    textTransform: 'capitalize',
    fontFamily: FontFamily.publicSansBold,
    textAlign: 'left',
    color: Color.white,
    fontWeight: '700',
    letterSpacing: -0.2,
    fontSize: FontSize.size_5xs,
  },
  fenlei: {
    top: 8,
    backgroundColor: Color.gray,
    paddingHorizontal: Padding.p_9xs,
    paddingVertical: Padding.p_11xs,
    flexDirection: 'row',
    borderRadius: Border.br_11xs,
    left: 8,
    position: 'absolute',
  },
  timeForNext: {
    marginTop: -10,
    fontFamily: FontFamily.interMedium,
    fontWeight: '500',
    textAlign: 'left',
    color: Color.white,
    fontSize: FontSize.size_5xs,
  },
  text1: {
    marginLeft: 2,
  },
  container: {
    marginLeft: 2,
  },
  frameWrapper: {
    position: 'absolute',
    top: 10,
    // marginTop: ,
    // alignItems: 'flex-end',
  },
  timeForNextBookingParent: {
    top: 79,
    left: 8,
    position: 'absolute',
  },
  frameParent: {
    borderRadius: 12,
    shadowColor: 'rgba(0, 0, 0, 0.1)',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 8,
    elevation: 8,
    shadowOpacity: 1,
    height: designToDp(114),
    width: designToDp(172),
    backgroundColor: Color.white,
  },
  text5: {
    fontSize: FontSize.size_xs,
    color: Color.color2,
    fontFamily: FontFamily.interBold,
    textAlign: 'left',
    fontWeight: '700',
    letterSpacing: -0.2,
  },
  ticket: {
    color: Color.color1,
    fontSize: FontSize.size_4xs,
    fontFamily: FontFamily.interMedium,
    fontWeight: '500',
    textAlign: 'left',
    letterSpacing: -0.2,
  },
  parent: {
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
  card3digitInner: {
    padding: Padding.p_5xs,
    width: designToDp(172),
    alignItems: 'center',
  },
  card3digit: {
    borderRadius: Border.br_5xs,
    overflow: 'hidden',
    backgroundColor: Color.white,
  },
});

export default CardBackTime;
