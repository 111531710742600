import * as React from 'react';
import {StyleSheet, View, Image, TouchableOpacity} from 'react-native';
import {
  FontSize,
  Color,
  FontFamily,
  Border,
  Padding,
} from '../card.common.styles';
import {designToDp} from '@utils';
import Text from '@/components/basic/text';

const CardKerlala = (props: {data: any; key: any; onPress?: () => void}) => {
  const {data, onPress} = props;

  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.cardKerlala}>
        <View style={styles.frameParentLayout}>
          <View style={[styles.groupWrapper, styles.frameParentLayout]}>
            <Image
              style={{width: '100%', height: '100%'}}
              source={{uri: data?.backImg}}
            />
            <View style={styles.lakhsParent}>
              <Text style={styles.lakhs}>{data?.lotteryMoney} Lakhs</Text>
              <Text style={styles.winPrize}>WIN PRIZE</Text>
            </View>
          </View>
          <Text style={styles.noFf31}>
            {'NO.'}
            <br />
            {'FF-31'}
          </Text>
          <View style={styles.fenlei}>
            <Text style={styles.weeklyLottery}>weekly lottery</Text>
          </View>
          <Text
            fontFamily="fontInterBold"
            style={[styles.onlineOnly, styles.textTypo]}>
            {data?.lotteryType}
          </Text>
        </View>
        <View style={[styles.parent, styles.parentFlexBox]}>
          <Text style={[styles.text, styles.textTypo]}>
            ₹{data?.lotteryPrice}
          </Text>
          <View style={styles.fri5530PmParent}>
            <Text style={styles.hrs3MinsTypo}>Fri {data?.drawTime}</Text>
            <View style={[styles.bxsHourglass1Parent, styles.parentFlexBox]}>
              <Image
                style={styles.bxsHourglass1Icon}
                //   contentFit="cover"
                source={{uri: ''}}
              />
              <Text style={[styles.hrs3Mins, styles.hrs3MinsTypo]}>
                5 hrs 3 mins
              </Text>
            </View>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  frameParentLayout: {
    height: designToDp(114),
    width: designToDp(172),
  },
  textTypo: {
    // fontSize: FontSize.size_xs,
    textAlign: 'left',
    fontWeight: '700',
    transform: [{skewX: '-10deg'}],
  },
  parentFlexBox: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  hrs3MinsTypo: {
    color: Color.color1,
    fontFamily: FontFamily.interMedium,
    fontWeight: '500',
    textAlign: 'right',
    letterSpacing: -0.18,
    fontSize: FontSize.size_4xs,
  },
  lakhs: {
    width: 120,
    top: '37.47%',
    left: '4.81%',
    fontSize: 18,
    textAlign: 'left',
    fontWeight: '700',
    color: '#FFF8B8',
    fontFamily: 'Anybody',
    position: 'absolute',
    transform: [{skewX: '-10deg'}],
  },
  winPrize: {
    height: '30.77%',
    width: '87.95%',
    top: -10,
    left: '4.14%',
    color: '#FF9900',
    fontSize: FontSize.size_4xs,
    textAlign: 'left',
    fontFamily: FontFamily.anybodyBold,
    fontWeight: '700',
    position: 'absolute',
  },
  lakhsParent: {
    height: '19.49%',
    width: '51.04%',
    top: '53.62%',
    right: '43.72%',
    bottom: '36.89%',
    left: '5.23%',
    position: 'absolute',
  },
  groupWrapper: {
    top: 0,
    left: 0,
    backgroundColor: Color.whitesmoke_200,
    position: 'absolute',
    overflow: 'hidden',
  },
  noFf31: {
    color: '#fff',
    right: 5,
    textAlign: 'right',
    top: 8,
    fontFamily: FontFamily.publicSansBold,
    letterSpacing: -0.2,
    fontSize: FontSize.size_4xs,
    fontWeight: '700',
    position: 'absolute',
  },
  weeklyLottery: {
    fontSize: FontSize.size_5xs,
    textTransform: 'capitalize',
    color: '#fff',
    fontFamily: FontFamily.publicSansBold,
    letterSpacing: -0.2,
    textAlign: 'left',
    fontWeight: '700',
  },
  fenlei: {
    left: 8,
    borderRadius: Border.br_11xs,
    backgroundColor: Color.gray_100,
    paddingHorizontal: Padding.p_9xs,
    paddingVertical: Padding.p_11xs,
    flexDirection: 'row',
    top: 8,
    position: 'absolute',
  },
  onlineOnly: {
    top: 30,
    left: 11,
    color: '#9069FF',
    fontSize: FontSize.size_xs,
    fontFamily: FontFamily.anybodyBold,
    position: 'absolute',
  },
  text: {
    fontFamily: FontFamily.interBold,
    color: '#000',
    width: designToDp(66),
    height: designToDp(14),
    fontSize: 16,
    fontWeight: '800',
    // letterSpacing: -0.2,
    // fontSize: FontSize.size_xs,
  },
  bxsHourglass1Icon: {
    width: 9,
    height: 9,
    overflow: 'hidden',
  },
  hrs3Mins: {
    marginLeft: 4,
  },
  bxsHourglass1Parent: {
    justifyContent: 'flex-end',
    marginTop: 2,
  },
  fri5530PmParent: {
    alignItems: 'flex-end',
  },
  parent: {
    backgroundColor: '#fff',
    padding: Padding.p_5xs,
    justifyContent: 'space-between',
    width: designToDp(172),
    alignItems: 'center',
  },
  cardKerlala: {
    borderRadius: Border.br_5xs,
    backgroundColor: Color.color2,
    overflow: 'hidden',
  },
});

export default CardKerlala;
