export function convertImgToObscureUrl(url: string) {
  const lastIndex = url.lastIndexOf('?');
  //
  const obscureParam =
    'x-oss-process=image/resize,m_fill,w_100,limit_0/blur,r_10,s_10/quality,q_10';
  if (lastIndex !== -1 && lastIndex !== url.length - 1) {
    return `${url}&${obscureParam}`;
  }
  return `${url}?${obscureParam}`;
}
