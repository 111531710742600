/* eslint-disable react-native/no-inline-styles */
import {
  View,
  StyleSheet,
  Image,
  Dimensions,
  Text,
  TouchableOpacity,
} from 'react-native';
import {useEffect, useState} from 'react';
import Swiper from '@basicComponents/swiper';
import {Screen2, Screen1} from '@basicComponents/navs';
import {AnchorTabRootView} from '@basicComponents/tab';
import React from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {designToDp, navigateTo, postMessage, http} from '@utils';
import {SafeAny} from '@types';
import config from '@/config';
import {paddingsSize} from '@/components/style';
import LinearGradient from '@basicComponents/linear-gradient';
import {ScratchList, getScratchList} from './home.service';

const getTokenValue = async () => {
  try {
    const value = await AsyncStorage.getItem('token');
    return value;
  } catch (error) {
    console.log(error);
  }
};
const token = async () => {
  const _token = await getTokenValue();
  return _token;
};

let hotGameList: any[] = [];

const Home = ({navigation}: any) => {
  const [digitGame, setDigitGame]: any = useState([]);
  const [bannerList, setbannerList]: any = useState([]);
  const [statta, setStatta]: any = useState([]);
  const [dice, setDice]: any = useState([]);
  const [kerlala, setKerlala]: any = useState([]);
  const [scratchList, setScratchList] = useState<ScratchList>([]);
  useEffect(() => {
    getScratchList().then(res => {
      setScratchList(
        res.map(v => ({
          src: v.cover,
          ...v,
        })),
      );
    });
    http.get('app/diceThree/noticeCheckOut').then();

    http.post('app/banner/manage/list', {type: 2}).then(res => {
      res ? setbannerList(res) : setbannerList([]);
    });
    //3 digit game
    http.post('app/pick/info/list').then(res => {
      // setDigitGame(res);
      res ? setDigitGame(res) : setDigitGame([]);
    });
    //statta
    http
      .post<SafeAny, SafeAny[]>('app/matka/lottery/home/list', {type: 1})
      .then(res => {
        if (res) {
          res.forEach(
            (item: {
              openResultNum: any;
              openNum: string;
              closeResultNum: any;
              closeNum: string;
            }) => {
              if (item.openResultNum) {
                var num = item.openResultNum;
                var hundred: any = num / 100;
                var fo: any = (num % 100) / 10;
                var on: any = (num % 100) % 10;
                hundred = parseInt(hundred, 10);
                fo = parseInt(fo, 10);
                on = parseInt(on, 10);
                var num = hundred + fo + on;
                item.openNum = num >= 10 ? num % 10 : num;
              } else {
                item.openNum = '*';
              }
              if (item.closeResultNum) {
                var num = item.closeResultNum;
                var hundred: any = num / 100;
                var fo: any = (num % 100) / 10;
                var on: any = (num % 100) % 10;
                hundred = parseInt(hundred, 10);
                fo = parseInt(fo, 10);
                on = parseInt(on, 10);
                var num = hundred + fo + on;
                item.closeNum = num >= 10 ? num % 10 : num;
              } else {
                item.closeNum = '*';
              }
            },
          );
          setStatta(res);
        } else {
          setStatta([]);
        }
      });
    //app/diceThree/homeList
    http.post('app/diceThree/homeList').then(res => {
      res ? setDice(res) : setDice([]);
    });
    //kerlala
    http.post('app/lottery/type/list', {type: 1}).then(res => {
      res ? setKerlala(res) : setKerlala([]);
    });
    http.post<SafeAny, SafeAny[]>('app/hotGame/list').then(res => {
      res ? (hotGameList = res) : (hotGameList = []);
    });
  }, []);

  return (
    <View style={{backgroundColor: '#F6F7FA'}}>
      <AnchorTabRootView
        titleOptions={['Kelala', '3Digit', 'Color', 'Dice', 'Matka']}
        beforeTabContent={
          <>
            <View>
              <Swiper
                pictureWidth={
                  Dimensions.get('window').width - paddingsSize.l * 2
                }
                seamless={true}
                paddingRight={paddingsSize.l}
                height={designToDp(80)}
                autoPlay={true}
                hasIndicator={true}
                pictures={bannerList?.map((item: {bannerImg: string}) => ({
                  uri: item.bannerImg,
                }))}
                onItemPress={index => {
                  console.log(index);
                }}
              />
            </View>

            <View style={styles.vipNavs}>
              <TouchableOpacity
                onPress={() => {
                  postMessage('router:/pages/vip/vip');
                }}>
                <View style={styles.vipNavsItem}>
                  <Image
                    style={styles.vipNavsItemImg}
                    source={require('@/assets/icons/vip_2.gif')}
                  />
                  <Text style={styles.text}>Vip</Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  postMessage('open');
                }}>
                <View style={styles.vipNavsItem}>
                  <Image
                    style={styles.vipNavsItemImg}
                    source={require('@/assets/icons/_3.gif')}
                  />

                  <Text style={styles.text}>Lucky Spin</Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  postMessage('router:/pages/rebate/rebate');
                }}>
                <View style={styles.vipNavsItem}>
                  <Image
                    style={styles.vipNavsItemImg}
                    source={require('@/assets/icons/rebate.gif')}
                  />
                  <Text style={styles.text}>Rebate</Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  postMessage('getFree');
                }}>
                <View style={styles.vipNavsItem}>
                  <Image
                    style={styles.vipNavsItemImg}
                    source={require('@/assets/icons/_1.gif')}
                  />
                  <Text style={styles.text}>Free Lottery</Text>
                </View>
              </TouchableOpacity>
            </View>
            <View>
              <Swiper
                pictureWidth={designToDp(224)}
                height={designToDp(128)}
                paddingRight={8}
                autoPlay={true}
                pictures={hotGameList?.map(item => ({
                  uri: item.bigImg,
                }))}
                onItemPress={async index => {
                  let tokens = await token();
                  const item = hotGameList[index];
                  if (!tokens) {
                    postMessage('router:/pages/login/login');
                    return;
                  }
                  navigateTo(
                    `${item.skipLinks}${tokens}&homeurl=${config.webTopWindowUri}`,
                  );
                }}
              />
            </View>
            <View style={{marginBottom: -5}}>
              <Screen1
                data={scratchList}
                onPress={async () => {
                  postMessage('scratch:index');
                }}
                cardPress={(item: SafeAny) => {
                  postMessage(`scratch:detail:${item.id}`);
                }}
                title={'Scratch Off'}
                cardType="freecard"
              />
            </View>
          </>
        }
        afterTabContent={
          <>
            <View id="Kelala" style={{}}>
              <Screen1
                data={kerlala}
                topBg={false}
                onPress={() => {
                  navigation.navigate('Detail', {detailType: 'kelala'});
                }}
                title={'Kerala State Lottery'}
                icon={'little'}
                cardType="kerlala"
                cardPress={(item: any) => {
                  postMessage(JSON.stringify(item));
                  postMessage('router:/pages/kerala/kerala');
                }}
              />
            </View>
            <View style={{marginTop: -7}} id="3Digit">
              <Screen2
                cardType="backTime"
                data={digitGame}
                onPress={() => {
                  navigation.navigate('Detail', {detailType: '3digit'});
                }}
                onItemPress={data => {
                  postMessage(
                    'router:/pages/index/home?id=' +
                      data.id +
                      '&pickLogo=' +
                      data.pickLogo +
                      '&pickName=' +
                      data.pickName,
                  );
                }}
                title={'3 Digit Game'}
              />
            </View>
            <LinearGradient
              colors={['#FFF', 'rgba(255, 255, 255, 0.00)']}
              start={{x: 0, y: 0}}
              end={{x: 0, y: 1}}>
              <View style={{marginTop: -25, marginBottom: -5}} id="Color">
                <View style={styles.color}>
                  <View style={{backgroundColor: 'rgb(246, 247, 250)'}}>
                    <View style={styles.frameFlexBox}>
                      <Image
                        style={styles.image442Icon}
                        // contentFit="cover"
                        source={require('./static/little.webp')}
                      />
                      <Text style={styles.scratchOff}>Color Prediction</Text>
                    </View>
                  </View>
                  <View
                    style={{
                      // width: designToDp(351),
                      // height: designToDp(128),
                      margin: 'auto',
                      marginTop: -5,
                      borderRadius: 8,
                      overflow: 'hidden',
                    }}>
                    <TouchableOpacity
                      onPress={() => {
                        postMessage(
                          'router:/pages/colorPrediction/colorPrediction',
                        );
                      }}>
                      <Image
                        style={{
                          width: designToDp(351),
                          height: designToDp(128),
                        }}
                        source={require('./static/color.png')}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </LinearGradient>
            <View id="Dice" style={{marginTop: 12}}>
              <Screen1
                data={dice}
                topBg={false}
                onPress={() => {
                  navigation.navigate('Detail', {detailType: 'dicegame'});
                }}
                cardPress={data => {
                  postMessage(
                    `router:/pages/dice/dice?id=${data.id}&index=${data.index}`,
                  );
                }}
                icon={'little'}
                title={'Dice Game'}
                cardType="diceGame"
              />
            </View>
            <View id="Matka">
              <Screen2
                cardType="cardSatta"
                data={statta}
                title={'Satta Matka'}
                onPress={() => {
                  navigation.navigate('Detail', {detailType: 'satta'});
                }}
                onItemPress={data => {
                  postMessage(
                    'router:/pages/sattaMatka/sattaMatka?data=' +
                      JSON.stringify(data),
                  );
                }}
              />
            </View>
          </>
        }
      />
    </View>
  );
};

const styles = StyleSheet.create({
  topBanner: {
    width: '100%',
    /* height: 72, */
    margin: 'auto',
    // marginTop: 20,
    marginLeft: designToDp(10),
  },
  vipNavs: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    height: designToDp(90),
    margin: 'auto',
  },
  vipNavsItem: {
    width: 70,
    height: 83,
    textAlign: 'center',
  },
  vipNavsItemImg: {
    width: 64,
    height: 64,
  },
  text: {
    marginTop: 5,
    marginLeft: -5,
    color: '#31373D',
    width: 75,
    fontSize: 12,
    textAlign: 'center',
    fontWeight: '500',
  },
  tabs: {
    width: '100%',
    height: 48,
    backgroundColor: '#fff',
  },
  color: {
    overflow: 'hidden',
    width: '100%',
    // height: 210,
    // backgroundColor: '#fff',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  image442Icon: {
    // top: 20,
    // left: 5,
    width: 8,
    height: 8,
    // position: 'absolute',
  },
  frameFlexBox: {
    alignItems: 'center',
    width: Dimensions.get('window').width * 0.93,
    height: 52,
    flexDirection: 'row',
    margin: 'auto',
    marginBottom: 0,
    marginTop: 0,
  },
  scratchOff: {
    fontSize: 14,
    letterSpacing: 0,
    textTransform: 'capitalize',
    color: '#000',
    fontWeight: '700',
    marginLeft: 8,
  },
});
export default Home;
