import {SafeAny} from '@/types';
import {indusWinHttp} from '@/utils/request';

export type ScratchListIem = {
  cover: string;
  price: string;
  name: string;
  cover2: string;
  end_time: number | Date;
  cover_award: number;
  id: number;
  [k: string]: SafeAny;
};
export type ScratchList = ScratchListIem[];

/** 获取刮刮乐入口列表 */
export const getScratchList = () => {
  return indusWinHttp.get<null, ScratchList>(
    'iGaming/api/scratchoffGameList?packageId=1',
  );
};
