/* fonts */
export const FontFamily = {
  interMedium: 'Inter',
  interBold: 'Inter-Bold',
  interBlack: 'Inter-Black',
  dIN: 'DIN',
  publicSansBold: 'PublicSans-Bold',
  anybodyBold: 'Anybody-Bold',
};
/* font sizes */
export const FontSize = {
  size_3xs: 10,
  size_xs: 12,
  size_4xs: 9,
  size_5xs: 8,
};
/* Colors */
export const Color = {
  white: '#fff',
  color: '#31373d',
  color1: '#5f6975',
  color2: '#000',
  whitesmoke_100: '#e8ebee',
  whitesmoke_200: '#e8ebee',
  gray: 'rgba(0, 0, 0, 0.3)',
  color3: '#5f6975',
  color4: '#000',
  gray_100: 'rgba(0, 0, 0, 0.3)',
};
/* Paddings */
export const Padding = {
  p_5xs: 8,
  p_9xs: 4,
  p_11xs: 2,
  p_xs: 12,
};
/* border radiuses */
export const Border = {
  br_5xs: 8,
  br_9xs: 4,
  br_11xs: 2,
};
