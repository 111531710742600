import {StyleSheet} from 'react-native';
import {paddingsSize} from './base.variable';

export const padding = StyleSheet.create({
  /** 整体xxs(4)边距 */
  xxs: {
    padding: paddingsSize.xxs,
  },
  /** 左xxs(4)边距 */
  leftxxs: {
    paddingLeft: paddingsSize.xxs,
  },
  /** 顶xxs(4)边距 */
  topxxs: {
    paddingTop: paddingsSize.xxs,
  },
  /** 右xxs(4)边距 */
  rightxxs: {
    paddingRight: paddingsSize.xxs,
  },
  /** 底xxs(4)边距 */
  btmxxs: {
    paddingBottom: paddingsSize.xxs,
  },
  /** 上下xxs(4)边距 */
  tbxxs: {
    paddingTop: paddingsSize.xxs,
    paddingBottom: paddingsSize.xxs,
  },
  /** 左右xxs(4)边距 */
  lrxxs: {
    paddingLeft: paddingsSize.xxs,
    paddingRight: paddingsSize.xxs,
  },

  /** 整体xs(6)边距 */
  xs: {
    padding: paddingsSize.xs,
  },
  /** 左xs(6)边距 */
  leftxs: {
    paddingLeft: paddingsSize.xs,
  },
  /** 顶xs(6)边距 */
  topxs: {
    paddingTop: paddingsSize.xs,
  },
  /** 右xs(6)边距 */
  rightxs: {
    paddingRight: paddingsSize.xs,
  },
  /** 底xs(6)边距 */
  btmxs: {
    paddingBottom: paddingsSize.xs,
  },
  /** 上下xs(6)边距 */
  tbxs: {
    paddingTop: paddingsSize.xs,
    paddingBottom: paddingsSize.xs,
  },
  /** 左右xs(6)边距 */
  lrxs: {
    paddingLeft: paddingsSize.xs,
    paddingRight: paddingsSize.xs,
  },

  /** 整体s(8)边距 */
  s: {
    padding: paddingsSize.s,
  },
  /** 左s(8)边距 */
  lefts: {
    paddingLeft: paddingsSize.s,
  },
  /** 顶s(8)边距 */
  tops: {
    paddingTop: paddingsSize.s,
  },
  /** 右s(8)边距 */
  rights: {
    paddingRight: paddingsSize.s,
  },
  /** 底s(8)边距 */
  btms: {
    paddingBottom: paddingsSize.s,
  },
  /** 上下s(8)边距 */
  tbs: {
    paddingTop: paddingsSize.s,
    paddingBottom: paddingsSize.s,
  },
  /** 左右s(8)边距 */
  lrs: {
    paddingLeft: paddingsSize.s,
    paddingRight: paddingsSize.s,
  },

  /** 整体m(10)边距 */
  m: {
    padding: paddingsSize.m,
  },
  /** 左m(10)边距 */
  leftm: {
    paddingLeft: paddingsSize.m,
  },
  /** 顶m(10)边距 */
  topm: {
    paddingTop: paddingsSize.m,
  },
  /** 右m(10)边距 */
  rightm: {
    paddingRight: paddingsSize.m,
  },
  /** 底m(10)边距 */
  btmm: {
    paddingBottom: paddingsSize.m,
  },
  /** 上下m(10)边距 */
  tbm: {
    paddingTop: paddingsSize.m,
    paddingBottom: paddingsSize.m,
  },
  /** 左右m(10)边距 */
  lrm: {
    paddingLeft: paddingsSize.m,
    paddingRight: paddingsSize.m,
  },

  /** 整体l(12)边距 */
  l: {
    padding: paddingsSize.l,
  },
  /** 左l(12)边距 */
  leftl: {
    paddingLeft: paddingsSize.l,
  },
  /** 顶l(12)边距 */
  topl: {
    paddingTop: paddingsSize.l,
  },
  /** 右l(12)边距 */
  rightl: {
    paddingRight: paddingsSize.l,
  },
  /** 底l(12)边距 */
  btml: {
    paddingBottom: paddingsSize.l,
  },
  /** 上下l(12)边距 */
  tbl: {
    paddingTop: paddingsSize.l,
    paddingBottom: paddingsSize.l,
  },
  /** 左右l(12)边距 */
  lrl: {
    paddingLeft: paddingsSize.l,
    paddingRight: paddingsSize.l,
  },

  /** 整体xl(14)边距 */
  xl: {
    padding: paddingsSize.xl,
  },
  /** 左xl(14)边距 */
  leftxl: {
    paddingLeft: paddingsSize.xl,
  },
  /** 顶xl(14)边距 */
  topxl: {
    paddingTop: paddingsSize.xl,
  },
  /** 右xl(14)边距 */
  rightxl: {
    paddingRight: paddingsSize.xl,
  },
  /** 底xl(14)边距 */
  btmxl: {
    paddingBottom: paddingsSize.xl,
  },
  /** 上下xl(14)边距 */
  tbxl: {
    paddingTop: paddingsSize.xl,
    paddingBottom: paddingsSize.xl,
  },
  /** 左右xl(14)边距 */
  lrxl: {
    paddingLeft: paddingsSize.xl,
    paddingRight: paddingsSize.xl,
  },

  /** 整体xxl(16)边距 */
  xxl: {
    padding: paddingsSize.xxl,
  },
  /** 左xxl(16)边距 */
  leftxxl: {
    paddingLeft: paddingsSize.xxl,
  },
  /** 顶xxl(16)边距 */
  topxxl: {
    paddingTop: paddingsSize.xxl,
  },
  /** 右xxl(16)边距 */
  rightxxl: {
    paddingRight: paddingsSize.xxl,
  },
  /** 底xxl(16)边距 */
  btmxxl: {
    paddingBottom: paddingsSize.xxl,
  },
  /** 上下xxl(16)边距 */
  tbxxl: {
    paddingTop: paddingsSize.xxl,
    paddingBottom: paddingsSize.xxl,
  },
  /** 左右xxl(16)边距 */
  lrxxl: {
    paddingLeft: paddingsSize.xxl,
    paddingRight: paddingsSize.xxl,
  },
});

export const margin = StyleSheet.create({
  /** 整体xxs(4)边距 */
  xxs: {
    margin: paddingsSize.xxs,
  },
  /** 左xxs(4)边距 */
  leftxxs: {
    marginLeft: paddingsSize.xxs,
  },
  /** 顶xxs(4)边距 */
  topxxs: {
    marginTop: paddingsSize.xxs,
  },
  /** 右xxs(4)边距 */
  rightxxs: {
    marginRight: paddingsSize.xxs,
  },
  /** 底xxs(4)边距 */
  btmxxs: {
    marginBottom: paddingsSize.xxs,
  },
  /** 上下xxs(4)边距 */
  tbxxs: {
    marginTop: paddingsSize.xxs,
    marginBottom: paddingsSize.xxs,
  },
  /** 左右xxs(4)边距 */
  lrxxs: {
    marginLeft: paddingsSize.xxs,
    marginRight: paddingsSize.xxs,
  },

  /** 整体xs(6)边距 */
  xs: {
    margin: paddingsSize.xs,
  },
  /** 左xs(6)边距 */
  leftxs: {
    marginLeft: paddingsSize.xs,
  },
  /** 顶xs(6)边距 */
  topxs: {
    marginTop: paddingsSize.xs,
  },
  /** 右xs(6)边距 */
  rightxs: {
    marginRight: paddingsSize.xs,
  },
  /** 底xs(6)边距 */
  btmxs: {
    marginBottom: paddingsSize.xs,
  },
  /** 上下xs(6)边距 */
  tbxs: {
    marginTop: paddingsSize.xs,
    marginBottom: paddingsSize.xs,
  },
  /** 左右xs(6)边距 */
  lrxs: {
    marginLeft: paddingsSize.xs,
    marginRight: paddingsSize.xs,
  },

  /** 整体s(8)边距 */
  s: {
    margin: paddingsSize.s,
  },
  /** 左s(8)边距 */
  lefts: {
    marginLeft: paddingsSize.s,
  },
  /** 顶s(8)边距 */
  tops: {
    marginTop: paddingsSize.s,
  },
  /** 右s(8)边距 */
  rights: {
    marginRight: paddingsSize.s,
  },
  /** 底s(8)边距 */
  btms: {
    marginBottom: paddingsSize.s,
  },
  /** 上下s(8)边距 */
  tbs: {
    marginTop: paddingsSize.s,
    marginBottom: paddingsSize.s,
  },
  /** 左右s(8)边距 */
  lrs: {
    marginLeft: paddingsSize.s,
    marginRight: paddingsSize.s,
  },

  /** 整体m(10)边距 */
  m: {
    margin: paddingsSize.m,
  },
  /** 左m(10)边距 */
  leftm: {
    marginLeft: paddingsSize.m,
  },
  /** 顶m(10)边距 */
  topm: {
    marginTop: paddingsSize.m,
  },
  /** 右m(10)边距 */
  rightm: {
    marginRight: paddingsSize.m,
  },
  /** 底m(10)边距 */
  btmm: {
    marginBottom: paddingsSize.m,
  },
  /** 上下m(10)边距 */
  tbm: {
    marginTop: paddingsSize.m,
    marginBottom: paddingsSize.m,
  },
  /** 左右m(10)边距 */
  lrm: {
    marginLeft: paddingsSize.m,
    marginRight: paddingsSize.m,
  },

  /** 整体l(12)边距 */
  l: {
    margin: paddingsSize.l,
  },
  /** 左l(12)边距 */
  leftl: {
    marginLeft: paddingsSize.l,
  },
  /** 顶l(12)边距 */
  topl: {
    marginTop: paddingsSize.l,
  },
  /** 右l(12)边距 */
  rightl: {
    marginRight: paddingsSize.l,
  },
  /** 底l(12)边距 */
  btml: {
    marginBottom: paddingsSize.l,
  },
  /** 上下l(12)边距 */
  tbl: {
    marginTop: paddingsSize.l,
    marginBottom: paddingsSize.l,
  },
  /** 左右l(12)边距 */
  lrl: {
    marginLeft: paddingsSize.l,
    marginRight: paddingsSize.l,
  },

  /** 整体xl(14)边距 */
  xl: {
    margin: paddingsSize.xl,
  },
  /** 左xl(14)边距 */
  leftxl: {
    marginLeft: paddingsSize.xl,
  },
  /** 顶xl(14)边距 */
  topxl: {
    marginTop: paddingsSize.xl,
  },
  /** 右xl(14)边距 */
  rightxl: {
    marginRight: paddingsSize.xl,
  },
  /** 底xl(14)边距 */
  btmxl: {
    marginBottom: paddingsSize.xl,
  },
  /** 上下xl(14)边距 */
  tbxl: {
    marginTop: paddingsSize.xl,
    marginBottom: paddingsSize.xl,
  },
  /** 左右xl(14)边距 */
  lrxl: {
    marginLeft: paddingsSize.xl,
    marginRight: paddingsSize.xl,
  },

  /** 整体xxl(16)边距 */
  xxl: {
    margin: paddingsSize.xxl,
  },
  /** 左xxl(16)边距 */
  leftxxl: {
    marginLeft: paddingsSize.xxl,
  },
  /** 顶xxl(16)边距 */
  topxxl: {
    marginTop: paddingsSize.xxl,
  },
  /** 右xxl(16)边距 */
  rightxxl: {
    marginRight: paddingsSize.xxl,
  },
  /** 底xxl(16)边距 */
  btmxxl: {
    marginBottom: paddingsSize.xxl,
  },
  /** 上下xxl(16)边距 */
  tbxxl: {
    marginTop: paddingsSize.xxl,
    marginBottom: paddingsSize.xxl,
  },
  /** 左右xxl(16)边距 */
  lrxxl: {
    marginLeft: paddingsSize.xxl,
    marginRight: paddingsSize.xxl,
  },
});
