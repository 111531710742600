import {SafeAny} from '@types';
import {Linking, Platform} from 'react-native';
import config from '@/config';

export function postMessage(data: SafeAny) {
  if (Platform.OS === 'web') {
    window.top?.postMessage(data, config.webTopWindowUri);
  }
}

export function navigateTo(url: string) {
  if (Platform.OS === 'web' && window.top) {
    window.top.location.href = url;
  } else {
    Linking.openURL(url);
  }
}
