import {BaseRouteComponentProps, SafeAny} from '@types';
import React, {ReactNode, useEffect, useState} from 'react';
import {
  View,
  StyleSheet,
  ScrollView,
  Dimensions,
  Text,
  Image,
  Pressable,
} from 'react-native';
import {
  CardKerlala,
  CardBackTime,
  CardDice,
  CardSatta,
} from '@basicComponents/card';
import {background, fill, flex, font, marginsSize, paddingsSize} from '@style';
import {http} from '@/utils/request';
import {postMessage} from '@/utils/web';

enum DetailType {
  kelala = 'kelala',
  threeDigit = '3digit',
  dicegame = 'dicegame',
  satta = 'satta',
}

interface DetailsProps extends BaseRouteComponentProps {
  route: {
    params: {
      detailType: DetailType;
    };
  };
}

// interface AllCardData {
//   id: string;
//   time?: number;
//   src: string;
//   price?: string | number;
//   title?: string;
//   openTime?: string;
//   closeTime?: string;
// }

type AllCardData = SafeAny;

type ProcessFn = (data: AllCardData, i?: number) => ReactNode;

const renderItemMap: Record<DetailType, ProcessFn> = {
  [DetailType.kelala]: (data: AllCardData) => {
    return (
      <View style={styles.card} key={data.id}>
        <CardKerlala
          data={data}
          key={data.id}
          onPress={() => {
            postMessage(JSON.stringify(data));
            postMessage('router:/pages/kerala/kerala');
          }}
        />
      </View>
    );
  },
  [DetailType.threeDigit]: (data: AllCardData) => {
    return (
      <View style={styles.card} key={data.id}>
        <CardBackTime
          data={data}
          key={data.id}
          onPress={_data => {
            postMessage(
              'router:/pages/index/home?id=' +
                _data.id +
                '&pickLogo=' +
                _data.pickLogo +
                '&pickName=' +
                _data.pickName,
            );
          }}
        />
      </View>
    );
  },
  [DetailType.dicegame]: (data: AllCardData, i) => {
    return (
      <View style={styles.card} key={data.id}>
        <CardDice
          data={data}
          index={0}
          onPress={() => {
            postMessage(`router:/pages/dice/dice?id=${data.id}&index=${i}`);
          }}
        />
      </View>
    );
  },
  [DetailType.satta]: (data: AllCardData) => {
    return (
      <View style={styles.card} key={data.id}>
        <CardSatta
          data={data}
          onItemPress={() => {
            postMessage(
              'router:/pages/sattaMatka/sattaMatka?data=' +
                JSON.stringify(data),
            );
          }}
        />
      </View>
    );
  },
};

const Details: React.FC<DetailsProps | any> = props => {
  const {
    route: {params: {detailType}} = {params: {detailType: DetailType.kelala}},
  } = props;

  const renderItem = renderItemMap[detailType as DetailType];

  const [data, setData] = useState<AllCardData[]>([]);

  const listDetail = async () => {
    if (detailType === DetailType.dicegame) {
      const res = await http.post<SafeAny, SafeAny[]>(
        '/app/diceThree/homeList',
      );
      setData(res);
    }
    if (detailType === DetailType.kelala) {
      const res = await http.post<SafeAny, SafeAny[]>('app/lottery/type/list', {
        type: 1,
      });
      setData(res);
    }
    if (detailType === DetailType.threeDigit) {
      const res = await http.post<SafeAny, SafeAny[]>('app/pick/info/list');
      setData(res);
    }
    if (detailType === DetailType.satta) {
      const res = await http.post<SafeAny, SafeAny[]>(
        'app/matka/lottery/home/list',
        {
          type: 1,
        },
      );
      res.forEach(
        (item: {
          openResultNum: any;
          openNum: string;
          closeResultNum: any;
          closeNum: string;
        }) => {
          if (item.openResultNum) {
            var num = item.openResultNum;
            var hundred: any = num / 100;
            var fo: any = (num % 100) / 10;
            var on: any = (num % 100) % 10;
            hundred = parseInt(hundred, 10);
            fo = parseInt(fo, 10);
            on = parseInt(on, 10);
            var num = hundred + fo + on;
            item.openNum = num >= 10 ? num % 10 : num;
          } else {
            item.openNum = '*';
          }
          if (item.closeResultNum) {
            var num = item.closeResultNum;
            var hundred: any = num / 100;
            var fo: any = (num % 100) / 10;
            var on: any = (num % 100) % 10;
            hundred = parseInt(hundred, 10);
            fo = parseInt(fo, 10);
            on = parseInt(on, 10);
            var num = hundred + fo + on;
            item.closeNum = num >= 10 ? num % 10 : num;
          } else {
            item.closeNum = '*';
          }
        },
      );
      setData(res);
    }
  };

  useEffect(() => {
    listDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailType]);

  return (
    <ScrollView style={[styles.view, background.white]}>
      <View style={[flex.flex1, flex.col, styles.innerView]}>
        <Pressable
          style={[
            flex.row,
            flex.centerByCol,
            {
              padding: paddingsSize.l,
            },
          ]}
          onPress={() => {
            props.navigation.navigate('Singam');
          }}>
          <Image
            source={require('@/components/assets/icons/left.png')}
            style={{width: 16, height: 16}}
          />
          <Text style={[font.fl, font.bold, {marginLeft: marginsSize.l}]}>
            {detailType} game
          </Text>
        </Pressable>
        <View style={[flex.flex1, fill.fillW, flex.row, flex.wrap]}>
          {data.map((d, i) => renderItem(d, i))}
        </View>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  view: {
    width: '100%',
    height: Dimensions.get('window').height,
  },
  innerView: {
    paddingVertical: 12,
    paddingHorizontal: 7,
  },
  card: {
    borderRadius: 5,
    marginTop: 12,
    marginLeft: 7,
    overflow: 'hidden',
  },
});
export default Details;
