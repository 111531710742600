import * as React from 'react';
// import LinearGradient from '../../linear-gradient';
import {
  StyleSheet,
  View,
  Text,
  Image,
  ImageBackground,
  TouchableOpacity,
} from 'react-native';
import {
  Color,
  FontFamily,
  FontSize,
  Padding,
  Border,
} from '../card.common.styles';
import {designToDp} from '@utils';
import {flex} from '@style';

interface CardSattaData {
  isClose: number;
  lotteryName: string;
  closeNum?: number;
  openNum?: number;
  closeDraw?: string;
  openDraw?: string;
  backImg?: string | undefined;
  openResultNum?: string;
  closeResultNum?: string;
}
interface CardSattaProps {
  data: CardSattaData;
  onItemPress?: (data: CardSattaData) => void;
}

const CardSatta = (props: CardSattaProps) => {
  const {data, onItemPress} = props;

  // eslint-disable-next-line react/no-unstable-nested-components
  const TopThree = () => {
    return <Text>{data?.openResultNum ? data?.openResultNum : '***'}</Text>;
  };
  // eslint-disable-next-line react/no-unstable-nested-components
  const LastThree = () => {
    return <Text>{data?.closeResultNum ? data?.closeResultNum : '***'}</Text>;
  };

  return (
    <TouchableOpacity
      onPress={() => {
        if (data?.isClose === 2) {
          return;
        }
        onItemPress && onItemPress(data);
      }}
      style={{width: designToDp(172)}}>
      <View style={styles.cardSatta}>
        <View style={[styles.frameParent, styles.parentLayout]}>
          <View style={[styles.rectangleParent, styles.parentLayout]}>
            <Image
              source={require('./assets/closed.png')}
              // eslint-disable-next-line react-native/no-inline-styles
              style={[styles.closed, {zIndex: data?.isClose === 2 ? 999 : 0}]}
            />
            <ImageBackground
              source={{uri: data?.backImg}}
              style={styles?.backImg}>
              <View style={[styles.frameChild, flex.center]}>
                <Text style={[styles.text, styles.textClr]}>
                  <TopThree />-{data.openNum}
                  {data.closeNum}-
                  <LastThree />
                </Text>
              </View>
              <View
                // eslint-disable-next-line react-native/no-inline-styles
                style={{
                  // position: 'absolute',
                  width: '90%',
                  margin: 'auto',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  // bottom: '10%',
                  marginBottom: '5%',
                }}>
                <Text style={styles.textClr}>₹10/BID</Text>
                <Text style={styles.textClr}>-</Text>
                <Text style={styles.textClr}>WIN ₹100000</Text>
              </View>
              {/* <Text style={[styles.bidWin, styles.textClr]}>
                ₹10/Bid - Win ₹100000
              </Text> */}
            </ImageBackground>
          </View>
          {/* <LinearGradient
            colors={['#FFFAE9', '#FFFCEF', '#E39600']}
            start={{x: 0, y: 0}}
            end={{x: 1, y: 1}}
            style={{padding: 10}}> */}
          {/* <Text style={styles.title}>{data.lotteryName}</Text> */}
          {/* </LinearGradient> */}
        </View>
        <View style={[styles.frameGroup, styles.groupFlexBox]}>
          <View>
            <View style={styles.groupFlexBox}>
              <Image
                style={styles.icon}
                // contentFit="cover"
                source={require('./assets/open.png')}
              />
              <Text style={styles.open}>OPEN</Text>
            </View>
            <Text style={[styles.pm, styles.pmTypo]}>{data?.openDraw}</Text>
          </View>
          <View style={styles.frameView}>
            <View style={[styles.group, styles.groupFlexBox]}>
              <Image
                style={styles.icon}
                // contentFit="cover"
                source={require('./assets/close.png')}
              />
              <Text style={styles.open}>CLOSE</Text>
            </View>
            <Text style={[styles.pm1, styles.pmTypo]}>{data?.closeDraw}</Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  closed: {
    width: designToDp(64),
    height: designToDp(64),
    position: 'absolute',
    right: 2,
    top: 8,
  },
  title: {
    width: designToDp(94),
    height: designToDp(28),
    margin: 'auto',
    marginTop: 15,
    lineHeight: 15,
    textAlign: 'center',
    fontFamily: 'DIN Black',
    fontSize: 16,
    fontWeight: '900',
    letterSpacing: -0.3,
    color: '#E39600',
    backgroundClip: 'text',
  },
  backImg: {
    flex: 1,
    esizeMode: 'cover',
  },
  parentLayout: {
    height: designToDp(114),
    width: designToDp(172),
    overflow: 'hidden',
  },
  textClr: {
    color: '#fff',
    fontSize: 12,
    fontWeight: '700',
    // position: 'absolute',
  },
  groupFlexBox: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  pmTypo: {
    marginTop: 2,
    color: '#000',
    fontFamily: FontFamily.interMedium,
    fontWeight: '500',
    letterSpacing: -0.2,
    fontSize: FontSize.size_4xs,
  },
  frameChild: {
    top: '35%',
    left: '11.2%',
    borderRadius: 32,
    backgroundColor: Color.gray_100,
    borderStyle: 'solid',
    borderColor: 'rgba(255, 255, 255, 0.2)',
    borderWidth: 1,
    width: designToDp(134),
    height: designToDp(34),
    position: 'absolute',
  },
  bidWin: {
    top: 97,
    left: 3,
    paddingLeft: 10,
    fontSize: FontSize.size_xs,
    letterSpacing: 0,
    lineHeight: 11,
    textTransform: 'uppercase',
    fontWeight: '700',
    fontFamily: FontFamily.dIN,
    textAlign: 'left',
  },
  rectangleParent: {
    top: 0,
    left: 0,
    backgroundColor: Color.whitesmoke_100,
    position: 'absolute',
  },
  text: {
    /* top: 57,
    left: 39, */
    fontSize: 16,
    fontWeight: '900',
    fontFamily: FontFamily.interBlack,
    textAlign: 'center',
    textShadowColor: 'rgba(0, 0, 0, 0.5)',
    textShadowOffset: {
      width: 0,
      height: 1,
    },
    textShadowRadius: 0,
  },
  frameParent: {
    shadowColor: 'rgba(0, 0, 0, 0.1)',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 8,
    elevation: 8,
    shadowOpacity: 1,
    backgroundColor: Color.color,
  },
  icon: {
    width: designToDp(10),
    height: designToDp(10),
    overflow: 'hidden',
  },
  open: {
    color: '#ccc',
    marginLeft: 2,
    fontFamily: FontFamily.interMedium,
    fontWeight: '500',
    letterSpacing: -0.2,
    fontSize: FontSize.size_4xs,
    textAlign: 'left',
  },
  pm: {
    textAlign: 'left',
  },
  group: {
    justifyContent: 'flex-end',
  },
  pm1: {
    textAlign: 'right',
  },
  frameView: {
    alignItems: 'flex-end',
  },
  frameGroup: {
    paddingHorizontal: Padding.p_xs,
    paddingVertical: Padding.p_5xs,
    justifyContent: 'space-between',
    width: designToDp(172),
    backgroundColor: '#fff',
    flexDirection: 'row',
  },
  cardSatta: {
    width: designToDp(172),
    overflow: 'hidden',
    borderRadius: Border.br_5xs,
    // backgroundColor: Color.color,
  },
});

export default CardSatta;
