import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
  View,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  NativeSyntheticEvent,
  NativeScrollEvent,
  ImageRequireSource,
  ImageURISource,
  LayoutChangeEvent,
} from 'react-native';
import LazyImage from '../image';
import {
  background,
  borderRadius,
  flex,
  marginsSize,
  paddingsSize,
} from '@style';
import {designToDp} from '@/components/utils/adaptive';

interface SwiperProps {
  delay?: number;
  seamless?: boolean;
  autoPlay?: boolean;
  pictureWidth?: number;
  paddingRight?: number;
  height?: number;
  pictures: (ImageRequireSource | ImageURISource)[];
  model?: string;
  hasIndicator?: boolean;
  onItemPress?: (index: number) => void;
}

// function insertArr(arr: any[]) {
//   let newArr: any[] = [];
//   newArr = [...arr];
//   if (arr.length > 1) {
//     newArr.push(arr[0]);
//     return newArr;
//   }
//   return newArr;
// }

function getSwiperList(items: (ImageRequireSource | ImageURISource)[]) {
  if (items?.length === 0) {
    return [{uri: ''}];
  }
  let swiperList = Array(...items).concat(...Array(...items));
  return swiperList;
}

const ANIMATE_TIME = 800;

const Swiper: React.FC<SwiperProps> = props => {
  let {
    autoPlay = false,
    seamless = false,
    pictures,
    pictureWidth = 300,
    paddingRight = 0,
    delay = 3000,
    height = 150,
    hasIndicator = false,
    onItemPress,
  } = props;
  // 当前的第几个轮播图
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  // 取得溢出的全图片
  const overPictures = seamless ? getSwiperList(pictures) : pictures;
  // 取得单个图片样子的宽度
  const itemWidth = pictureWidth + paddingRight;
  const [scrolling, setScrolling] = useState<boolean>(false);
  const scrollView = useRef<ScrollView>(null);
  const timer = useRef<NodeJS.Timeout | null>(null);
  const playing = useRef<boolean>(false);
  const scrollX = useRef<number>(0);
  const scrollTimer = useRef<NodeJS.Timeout | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);

  const scrollToIndex = useCallback(
    (index: number) => {
      const scrollOffset = index * itemWidth;
      scrollView.current?.scrollTo({x: scrollOffset, animated: false});
      scrollX.current = scrollOffset;
    },
    [itemWidth],
  );

  const handlePlayEnd = useCallback(
    (toIndex: number) => {
      if (playing.current) {
        playing.current = false;
        scrollToIndex(toIndex);
      }
    },
    [scrollToIndex],
  );

  const scrollToIndexAnimated = useCallback(
    (index: number) => {
      const scrollOffset = index * itemWidth;
      scrollView.current?.scrollTo({x: scrollOffset, animated: true});
      playing.current = true;
      if (seamless) {
        setTimeout(() => {
          handlePlayEnd(index % (pictures.length || 1));
        }, ANIMATE_TIME);
      }
    },
    [handlePlayEnd, itemWidth, pictures.length, seamless],
  );

  const playingCurrentSwiper = useCallback(() => {
    const nextIndex = currentIndex + 1;
    setCurrentIndex(nextIndex % (pictures.length || 1));
    scrollToIndexAnimated(
      seamless ? nextIndex : nextIndex % (pictures.length || 1),
    );
  }, [currentIndex, pictures.length, scrollToIndexAnimated, seamless]);

  const playSwiper = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    timer.current = setTimeout(() => {
      if (!scrolling && autoPlay) {
        playingCurrentSwiper();
        playSwiper();
      }
    }, delay);
  }, [autoPlay, delay, playingCurrentSwiper, scrolling]);

  const handleLayout = (e: LayoutChangeEvent) => {
    const width = e.nativeEvent.layout.width;
    setContainerWidth(width);
  };

  useEffect(() => {
    playSwiper();
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = null;
      }
      if (scrollTimer.current) {
        clearTimeout(scrollTimer.current);
        scrollTimer.current = null;
      }
    };
  }, [playSwiper]);
  const handleScrolling = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    scrollX.current = e.nativeEvent.contentOffset.x;
  };
  const handleBeginDrag = () => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    if (scrollTimer.current) {
      clearTimeout(scrollTimer.current);
      scrollTimer.current = null;
    }
    setScrolling(true);
  };

  const handleEndDrag = () => {
    setTimeout(() => {
      const x = scrollX.current;
      let nowIndex = Math.round(x / itemWidth);
      const _currentIndex = nowIndex % (pictures.length || 1);
      setCurrentIndex(_currentIndex);
      scrollToIndex(_currentIndex);
    }, ANIMATE_TIME);
    // scrollToIndexAnimated(nowIndex);

    setScrolling(false);
  };

  const innerStyles = StyleSheet.create({
    indicator: {
      width: containerWidth,
    },
    swiperItemView: {
      paddingHorizontal: paddingRight,
    },
    swiperItem: {
      width: pictureWidth,
      height: height,
    },
    indicatorIndex: {
      opacity: 0.7,
    },
    indicatorIndexSelected: {
      width: designToDp(12),
    },
  });

  return (
    <View style={[styles.swiper]} onLayout={handleLayout}>
      <ScrollView
        horizontal={true}
        scrollEventThrottle={16}
        showsHorizontalScrollIndicator={false}
        bounces={false}
        overScrollMode={'never'}
        //控制视图能否被拖动
        scrollEnabled={true}
        ref={scrollView}
        pagingEnabled={true}
        // onMomentumScrollEnd={handlePlayEnd}
        onScroll={handleScrolling}
        onTouchStart={handleBeginDrag}
        onTouchEnd={handleEndDrag}>
        {overPictures.map((item, index) => {
          return (
            <TouchableOpacity
              key={index}
              activeOpacity={1}
              onPress={() => {
                onItemPress && onItemPress(index % pictures.length);
              }}>
              <View
                style={[
                  styles.swiperItem,
                  innerStyles.swiperItemView,
                  borderRadius.m,
                ]}>
                <LazyImage
                  imageStyle={[innerStyles.swiperItem, styles.swiperItemImage]}
                  imageUrl={typeof item === 'number' ? item : item.uri || ''}
                />
                {/* <Image
                  style={innerStyles.swiperItem}
                  source={item}
                  resizeMode="cover"
                /> */}
              </View>
            </TouchableOpacity>
          );
        })}
      </ScrollView>
      {hasIndicator && (
        <View
          style={[
            styles.indicator,
            innerStyles.indicator,
            flex.row,
            flex.center,
          ]}>
          {pictures.map((item, index) => (
            <View
              key={index}
              style={[
                styles.indicatorIndex,
                borderRadius.xs,
                index !== currentIndex
                  ? [background.white, innerStyles.indicatorIndex]
                  : [background.primary, innerStyles.indicatorIndexSelected],
              ]}
            />
          ))}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  swiper: {
    paddingVertical: paddingsSize.s,
    marginHorizontal: marginsSize.l,
    position: 'relative',
    // boxSizing: 'border-box',
    // paddingRight: 10,
  },
  swiperItem: {
    /* backgroundColor: '#ccc', */
    overflow: 'hidden',
    paddingLeft: 0,
    borderRadius: 5,
  },
  swiperItemImage: {
    resizeMode: 'stretch',
  },
  indicator: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    bottom: paddingsSize.s,
    paddingVertical: paddingsSize.xxs,
  },
  indicatorIndex: {
    width: designToDp(4),
    height: designToDp(4),
    marginRight: marginsSize.xxs,
  },
  // Img: {
  //   width: sW,
  //   height: height,
  // },
});

export default Swiper;
