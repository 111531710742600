import * as React from 'react';
import {StyleSheet, View, Text, ImageBackground} from 'react-native';
import {
  Border,
  Color,
  FontFamily,
  FontSize,
  Padding,
} from '../card.common.styles';
import {designToDp} from '@utils';
import {SafeAny} from '@types';
import {TouchableOpacity} from 'react-native';
interface CardProps {
  data: any;
  onPress?: (data: SafeAny) => void;
}

/* const getToken = async () => {
  return await AsyncStorage.getItem('token');
}; */

const FreeCard = (props: CardProps) => {
  const {data, onPress} = props;

  return (
    <TouchableOpacity
      onPress={async () => {
        onPress && onPress(data);
      }}>
      <View style={{width: '50%'}}>
        <View style={styles.property1cardInner}>
          <View style={[styles.frameItem, styles.framePosition]} />
          <ImageBackground source={{uri: data?.src}} style={styles.backImg} />
        </View>
        <View style={[styles.parent, styles.parentFlexBox]}>
          <View>
            {/* <Text style={[styles.free, styles.textTypo]}>FREE</Text> */}
            <Text style={[styles.text1, styles.text1Typo]}>₹{data?.price}</Text>
          </View>
          <View style={[styles.tryNowWrapper, styles.parentFlexBox]}>
            <Text style={[styles.tryNow, styles.text1Typo]}>try now</Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  backImg: {
    flex: 1,
    esizeMode: 'cover',
  },
  property1cardLayout: {
    borderRadius: Border.br_5xs,
    position: 'absolute',
    backgroundColor: Color.white,
    overflow: 'hidden',
  },
  framePosition: {
    left: 0,
    top: 0,
    height: designToDp(114),
    width: designToDp(172),
    position: 'absolute',
    overflow: 'hidden',
  },
  parentFlexBox: {
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: '#fff',
  },
  textLayout: {
    height: designToDp(14),
    width: designToDp(66),
    fontFamily: FontFamily.interBold,
    fontWeight: '700',
  },
  text1Typo: {
    fontSize: FontSize.size_3xs,
    textAlign: 'left',
    letterSpacing: -0.2,
  },
  textTypo: {
    textAlign: 'left',
    color: Color.color2,
    letterSpacing: -0.2,
    fontSize: FontSize.size_xs,
  },
  frameChild: {
    backgroundColor: '#e8ebef',
  },
  property1cardInner: {
    height: designToDp(114),
    width: designToDp(172),
    overflow: 'hidden',
    borderRadius: 8,
  },
  text: {
    textAlign: 'left',
    color: Color.color2,
    letterSpacing: -0.2,
    fontSize: FontSize.size_xs,
    height: designToDp(14),
    width: designToDp(66),
  },
  tryNow: {
    textTransform: 'capitalize',
    fontWeight: '500',
    fontFamily: FontFamily.interMedium,
    color: Color.color,
  },
  tryNowWrapper: {
    borderRadius: Border.br_9xs,
    borderStyle: 'solid',
    borderColor: '#d8e0ea',
    paddingHorizontal: Padding.p_5xs,
    paddingVertical: Padding.p_9xs,
    justifyContent: 'center',
    backgroundColor: Color.white,
    alignItems: 'center',
    flexDirection: 'row',
    borderWidth: 1,
  },
  parent: {
    height: 40,
    padding: Padding.p_5xs,
    justifyContent: 'space-between',
    width: designToDp(172),
    alignItems: 'center',
    flexDirection: 'row',
  },
  property1card: {
    top: 23,
    left: 20,
    backgroundColor: Color.white,
  },
  frameItem: {
    overflow: 'hidden',
    backgroundColor: '#e8ebee',
  },
  free: {
    fontFamily: FontFamily.interBold,
    fontWeight: '700',
    textAlign: 'left',
    color: Color.color2,
    letterSpacing: -0.2,
    fontSize: FontSize.size_xs,
  },
  text1: {
    // textDecoration: 'line-through',
    color: '#000',
    height: designToDp(14),
    fontSize: 12,
    width: designToDp(66),
    fontFamily: FontFamily.interBold,
    fontWeight: '700',
  },
  property1card1: {
    top: 20,
    left: 221,
    backgroundColor: Color.white,
  },
  card: {
    borderRadius: 5,
    borderStyle: 'dashed',
    borderColor: '#9747ff',
    width: 413,
    height: 197,
    overflow: 'hidden',
    borderWidth: 1,
  },
});

export default FreeCard;
