import {font} from '@style';
import React from 'react';
import {
  View,
  Text as RNtext,
  TextProps as RNTextProps,
  StyleSheet,
} from 'react-native';
interface TextProps extends RNTextProps {
  fontFamily?: 'fontDin' | 'fontAnybody' | 'fontInter' | 'fontInterBold'; // 添加你的属性值
}
const Text: React.FC<TextProps> = props => {
  const {style, fontFamily = 'fontInter', ...otherProps} = props;
  const mergedStyles = StyleSheet.flatten([font[fontFamily], style]);
  return (
    <View>
      <RNtext style={mergedStyles} {...otherProps} />
    </View>
  );
};

export default Text;
