import {convertImgToObscureUrl} from '@utils';
import React from 'react';
import {View, ViewStyle} from 'react-native';
import {
  Image,
  ImageStyle,
  StyleProp,
  ImageURISource,
  StyleSheet,
  ImageRequireSource,
} from 'react-native';
import {ImageUrlType} from './index.type';
// export type LazyImageProps = ImageProps;
export interface LazyImageProps {
  width?: number; // 图片宽度
  height?: number; // 图片高度
  imageUrl: ImageUrlType; // 图片url，如果是一个静态图片就直接用source
  radius?: number; // 圆角
  imageStyle?: StyleProp<ImageStyle>; // 图片的其他样式
  style?: StyleProp<ViewStyle>;
}
const LazyImage: React.FC<LazyImageProps> = props => {
  const {imageUrl, style, imageStyle, width, height, radius} = props;

  const blurredImageUrl: string =
    typeof imageUrl === 'string' ? convertImgToObscureUrl(imageUrl) : '';

  const source: ImageRequireSource | ImageURISource =
    typeof imageUrl === 'string' ? {uri: imageUrl} : imageUrl;

  return (
    <View style={[styles.view, style]}>
      {blurredImageUrl && (
        <Image
          style={[
            {
              width: width || 'auto',
              height: height || 'auto',
              borderRadius: radius || 8,
            },
            styles.image,
            imageStyle,
          ]}
          source={{uri: blurredImageUrl}}
        />
      )}
      <Image
        style={[
          {
            width: width || 'auto',
            height: height || 'auto',
            borderRadius: radius || 8,
          },
          styles.image,
          imageStyle,
          styles.realImageFloat,
        ]}
        source={source}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  view: {
    position: 'relative',
  },
  image: {
    resizeMode: 'contain',
  },
  realImageFloat: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
    backgroundColor: 'transparent',
  },
});

export default LazyImage;
