import React, {useEffect} from 'react';
import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import Home from './pages/home';
import {Linking, SafeAreaView} from 'react-native';
import Details from './pages/details';
import AsyncStorage from '@react-native-async-storage/async-storage';
const Stack = createStackNavigator();
const App = () => {
  useEffect(() => {
    Linking.getInitialURL().then(url => {
      let token: any = url?.split('=')[1];
      if (token) {
        AsyncStorage.setItem('token', token);
      }
    });
    var s = [];
    var hexDigits = '0123456789abcdef';
    for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010

    s[19] = hexDigits.substr((s[19] && 0x3) || 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23];

    var visitor = s.join('');

    AsyncStorage.getItem('visitor').then(res => {
      if (res) {
        return;
      } else {
        AsyncStorage.setItem('visitor', visitor);
      }
    });
  }, []);

  return (
    <SafeAreaView>
      <NavigationContainer>
        <Stack.Navigator>
          <Stack.Screen
            name="Singam"
            component={Home}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="Detail"
            component={Details}
            options={{headerShown: false}}
          />
        </Stack.Navigator>
      </NavigationContainer>
    </SafeAreaView>
  );
};
export default App;
