import React, {useState} from 'react';
import {Tab} from '@rneui/themed';
import {
  ViewStyle,
  StyleProp,
  StyleSheet,
  LayoutChangeEvent,
  View,
} from 'react-native';
import {background, fill, font} from '@style';
import {designToDp} from '@/components/utils/adaptive';

export interface IndecatorTabbarProps {
  index: number;
  // 每一个tab栏目的标题
  titles: string[];
  // tab栏目根部的样式
  style?: StyleProp<ViewStyle>;

  onChange?: (index: number) => void;
  onLayout?: (e: LayoutChangeEvent) => void;
}

const indicatorWidth = 20;
const IndecatorTabbar: React.FC<IndecatorTabbarProps> = props => {
  const {index, titles, style, onChange} = props;
  const [tabItemWidth, setTabItemWidth] = useState<number>(0);
  const varStyles = StyleSheet.create({
    indicator: {
      marginLeft: tabItemWidth ? (tabItemWidth - indicatorWidth) / 2 : 0,
    },
  });

  const onLayout = ({
    nativeEvent: {
      layout: {width},
    },
  }: LayoutChangeEvent) => {
    setTabItemWidth(width / titles.length);
  };
  return (
    <View style={[styles.container, styles.tab, style]} onLayout={onLayout}>
      <Tab
        value={index}
        onChange={onChange}
        style={[fill.fillW]}
        buttonStyle={styles.nonepadding}
        indicatorStyle={[
          styles.indicator,
          background.primary,
          varStyles.indicator,
        ]}
        onLayout={onLayout}
        titleStyle={active =>
          active ? [font.main, font.bold, font.fm] : [font.second, font.fm]
        }>
        {titles.map((title, i) => (
          <Tab.Item key={i} title={title} />
        ))}
      </Tab>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  tabH: {
    height: 25,
  },
  tab: {
    marginBottom: 8,
  },
  nonepadding: {
    paddingVertical: 0,
  },
  indicator: {
    width: designToDp(indicatorWidth),
    height: designToDp(4),
  },
});
export default IndecatorTabbar;
