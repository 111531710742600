import * as React from 'react';
import {
  StyleSheet,
  View,
  Text,
  ImageBackground,
  TouchableOpacity,
} from 'react-native';
import {
  Color,
  FontSize,
  FontFamily,
  Padding,
  Border,
} from '../card.common.styles';

interface CardGameProps {
  data: {src: ''};
  onPress: () => void;
}
const CardGame = (props: CardGameProps) => {
  const {data, onPress} = props;
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.cardGame}>
        <ImageBackground source={{uri: data.src}} style={styles.imgStyle}>
          <View style={styles.cardGameChild} />
        </ImageBackground>
        <View style={styles.gatesOfOlympusParent}>
          <Text style={[styles.gatesOfOlympus, styles.pragmaticPlayFlexBox]}>
            Gates of Olympus
          </Text>
          <Text style={[styles.pragmaticPlay, styles.pragmaticPlayFlexBox]}>
            PRAGMATIC PLAY
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  imgStyle: {
    flex: 1,
    esizeMode: 'cover',
  },
  pragmaticPlayFlexBox: {
    textAlign: 'justify',
    letterSpacing: -0.2,
  },
  cardGameChild: {
    // backgroundColor: Color.whitesmoke_100,
    height: 114,
    width: 172,
  },
  gatesOfOlympus: {
    fontSize: FontSize.size_xs,
    fontWeight: '700',
    fontFamily: FontFamily.interBold,
    color: Color.color2,
    width: 102,
    height: 14,
  },
  pragmaticPlay: {
    fontSize: 10,
    textTransform: 'capitalize',
    fontFamily: FontFamily.interMedium,
    color: Color.color1,
    marginTop: 4,
  },
  gatesOfOlympusParent: {
    height: 40,
    padding: Padding.p_5xs,
    justifyContent: 'center',
    width: 172,
  },
  cardGame: {
    borderRadius: Border.br_5xs,
    backgroundColor: '#fff',
    overflow: 'hidden',
  },
});

export default CardGame;
