import React, {ReactNode} from 'react';
import {StyleSheet, View} from 'react-native';
import LazyImage, {LazyImageProps} from './lazy-image';
export interface LazyImageBackgroundProps extends LazyImageProps {
  children?: ReactNode;
}
const LazyImageBackground: React.FC<LazyImageBackgroundProps> = props => {
  const {children, style, ...imageProps} = props;

  return (
    <View style={[styles.view, style]}>
      <LazyImage style={[styles.image]} {...imageProps} />
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  view: {
    position: 'relative',
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
});

export default LazyImageBackground;
