import axios, {AxiosResponse} from 'axios';
import md5 from '@/components/utils/md5';
import AsyncStorage from '@react-native-async-storage/async-storage';

const header = {
  'Content-Type': 'application/json',
  packageId: '3',
};

const getVisitorValue = async () => {
  try {
    const value = await AsyncStorage.getItem('visitor');
    return value;
  } catch (error) {
    console.error(error);
  }
};
let visitor = async () => {
  return await getVisitorValue();
};

const datas: any = {
  channel: 'h5',
  lang: 'English',
  packageId: 3,
  packageInfo: 'com.sambad.bull',
  reqDate: new Date().getTime(),
  versionCode: 243,
  visitor: visitor,
};

const obj = datas;
let result = '';
for (let item in obj) {
  if (obj[item] && String(obj[item])) {
    result += `&${item}=${obj[item]}`;
  }
}
if (result) {
  result = result.slice(1);
}
result += '&vfamx47613hb54tbtvmqaklgcxmdlrwc0e80t5fakts';
datas.sign = md5.hex_md5(result);

const createHTTP = (baseUrl: string) => {
  // 创建一个Axios实例
  const http = axios.create({
    baseURL: baseUrl,
    timeout: 6000, // 设置请求超时时间
    headers: header,
  });

  const getToken = async () => {
    const token = await AsyncStorage.getItem('token');
    return token;
  };

  // 请求拦截器
  http.interceptors.request.use(
    async config => {
      config.data = {
        ...config.data,
        ...datas,
      };
      //在未登录状态获取首页数据可以不传token
      if (await getToken()) {
        config.headers.Token = `${await getToken()}`;
      }
      return config;
    },
    (error: any) => {
      return Promise.reject(error);
    },
  );

  // 响应拦截器
  http.interceptors.response.use(
    <T>(response: AxiosResponse<{data: T; code: number; msg: string}>) => {
      if (response.status === 200 && response.data.code === 0) {
        return response.data.data;
      }
      return Promise.reject(response);
    },
    error => {
      console.error('响应拦截器错误:', error);
      return Promise.reject(error);
    },
  );

  return http;
};

const http = createHTTP('https://api.supergame123.xyz/');
const indusWinHttp = createHTTP('https://induswin.com/');

export {http, indusWinHttp};
