import {designToDp} from '../utils';

export const basicColor = {
  /** 主题色 */
  primary: '#9800F5',
  red: 'red',
  white: '#fff',
  dark: '#000',
};

export const fontColor = {
  main: '#000',
  second: '#31373D',
  accent: '#5F6975',
  secAccent: '#9FA5AC',
};

export const backgroundColor = {
  main: '#9800F5',
  second: '#F15802',
  accent: '#E20000',
  secAccent: '#009919',
  grey: '#D8E0EA',
  lightGrey: '#F6F7FA',
};

export const fontSize = {
  xs: 10,
  s: 12,
  m: 14,
  l: 16,
  xl: 20,
};

export const marginsSize = {
  xxs: 4,
  xs: 6,
  s: 8,
  m: 10,
  l: 12,
  xl: 14,
  xxl: 16,
};

export const paddingsSize = {
  xxs: 4,
  xs: 6,
  s: 8,
  m: 10,
  l: 12,
  xl: 14,
  xxl: 16,
};

export const iconSize = {
  s: designToDp(20),
  m: designToDp(28),
  l: designToDp(36),
};

export const borderRadiusSize = {
  xs: 4,
  s: 6,
  m: 8,
  l: 10,
  xl: 12,
};
