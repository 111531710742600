import React, {
  ReactNode,
  useMemo,
  useState,
  ReactElement,
  cloneElement,
  useCallback,
  useRef,
  Fragment,
} from 'react';
import {
  View,
  ViewStyle,
  StyleProp,
  ScrollView,
  StyleSheet,
  Image,
  Dimensions,
  LayoutChangeEvent,
} from 'react-native';
import IndecatorTabbar from './indicator-tabbar';
import {background, fill, flex} from '@style';
import {
  cloneThroughFragments,
  isNotObjectAndArray,
  isReactIterator,
} from '@utils';
import {designToDp} from '@/components/utils/adaptive';
import {marginsSize} from '@style';

interface TitleOptions {
  id: string;
  title?: string;
}

interface AnchorTabRootViewProps {
  titleOptions: (TitleOptions | string)[];
  beforeTabContent?: ReactNode;
  afterTabContent?: ReactNode;
  tabbarContainerStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
}

const anchorTabBackground = require('./assets/anchortab-bg.png');
const ballImage = require('./assets/ball.webp');

const AnchorTabRootView: React.FC<AnchorTabRootViewProps> = props => {
  const {
    titleOptions,
    beforeTabContent,
    afterTabContent,
    tabbarContainerStyle,
    style,
  } = props;
  const [index, setIndex] = useState<number>(0);
  const [topViewHeight, setTopViewHeight] = useState<number>(0);
  const anchorMap = useRef<Record<string, number>>({});
  const scrollViewRef = useRef<ScrollView>(null);
  // const viewHeight = Dimensions.get('window').height;
  const _titleOptions = titleOptions.map(t => {
    if (typeof t === 'string') {
      return {
        id: t,
        title: t,
      };
    }
    return {
      id: t.id,
      title: t.title || t.id,
    };
  });
  const titles = _titleOptions.map(({title}) => title);
  const handleLayout = useCallback(
    (e: LayoutChangeEvent, id: string) => {
      anchorMap.current[id] = e.nativeEvent.layout.y;
    },
    [anchorMap],
  );

  const handleTopViewLayout = useCallback((e: LayoutChangeEvent) => {
    const height = e.nativeEvent.layout.height;
    setTopViewHeight(height);
  }, []);

  const handleTabbarChange = useCallback(
    (i: number) => {
      const id = _titleOptions[i].id;
      scrollViewRef.current?.scrollTo({
        y: topViewHeight + anchorMap.current[id],
      });
      setIndex(i);
    },
    [_titleOptions, topViewHeight],
  );

  const copyChildren = useCallback(
    (element: ReactNode): ReactNode => {
      if (isNotObjectAndArray(element)) {
        return element;
      }

      if (isReactIterator(element)) {
        const _element = element as Iterable<ReactNode>;
        return Array(..._element).map(el => copyChildren(el));
      }

      const _el = element as ReactElement;
      if (_el.type === Fragment) {
        return copyChildren(cloneThroughFragments(_el.props.children));
      }
      if (_el.props.id) {
        const _id = _el.props.id;
        if (_titleOptions.some(v => v.id === _id)) {
          return cloneElement(_el, {
            onLayout: (e: LayoutChangeEvent) => {
              handleLayout(e, _id);
            },
          });
        }
        return element;
      }

      return element;
    },
    [_titleOptions, handleLayout],
  );
  const afterTabContentResult = useMemo(() => {
    return copyChildren(afterTabContent);
  }, [afterTabContent, copyChildren]);
  return (
    <ScrollView
      style={[
        fill.fillW,
        flex.col,
        {height: Dimensions.get('window').height},
        style,
      ]}
      ref={scrollViewRef}
      scrollEventThrottle={1}
      stickyHeaderIndices={[1]}>
      <View style={[fill.fillW, flex.col]} onLayout={handleTopViewLayout}>
        {beforeTabContent}
      </View>
      <View
        style={[fill.fillW, flex.row, flex.centerByCol, background.lightGrey]}>
        <Image style={[styles.tabbarBg]} source={anchorTabBackground} />
        <Image style={[styles.ball]} source={ballImage} />
        <IndecatorTabbar
          titles={titles}
          index={index}
          onChange={handleTabbarChange}
          style={tabbarContainerStyle}
        />
      </View>

      <View style={[flex.col]}>{afterTabContentResult}</View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  tabbarBg: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: Dimensions.get('window').width,
    height: designToDp(48),
    resizeMode: 'contain',
  },
  ball: {
    width: designToDp(24),
    height: designToDp(24),
    resizeMode: 'contain',
    marginLeft: marginsSize.xl,
  },
});

export default AnchorTabRootView;
