/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react-native/no-inline-styles */
import * as React from 'react';
// import { Image } from "expo-image";
import {
  StyleSheet,
  View,
  Text,
  Image,
  ScrollView,
  Dimensions,
  TouchableOpacity,
  NativeScrollEvent,
  NativeSyntheticEvent,
} from 'react-native';
import LinearGradient from '../linear-gradient';
import {
  FontFamily,
  Border,
  FontSize,
  Color,
  Padding,
} from './navs.common.style';
import {Card, CardGame, FreeCard, CardDice, CardKerlala} from '../card';
import {useRef, useState} from 'react';
import {designToDp, convertArrayToPairs, removeUndefinedKeys} from '@utils';
import {SafeAny} from '@types';
import LazyImage from '../image';
// import {LinearGradient} from '@/components/basic/linear-gradient';
const iconScratchOff = require('./assets/icon-scratch-off.webp');

const Screen1: React.FC<{
  data: any;
  onPress: () => void;
  title: string;
  icon?: string;
  topBg?: boolean;
  cardType?: string;
  cardPress?: (item: SafeAny) => void;
}> = props => {
  // const screenWidth = Dimensions.get('window').width;
  const cardList: any = useRef(null);
  const {
    data,
    onPress,
    title,
    icon = 'big',
    cardType = 'card',
    cardPress,
    topBg = true,
  } = props;

  const finalyData = convertArrayToPairs(data);
  finalyData.forEach((item: any) => {
    removeUndefinedKeys(item);
  });
  let index = 0;

  const [screenWidth, setScreenWidth] = useState(
    Dimensions.get('window').width,
  );

  const widthLayout = () => {
    setScreenWidth(Dimensions.get('window').width);
  };

  const prevBtn: any = useRef(null);
  const nextBtn: any = useRef(null);
  const prev = () => {
    if (index >= 0) {
      index -= 1;
      cardList.current.scrollTo({
        x: screenWidth * index,
        y: 0,
        animation: 'true',
      });
    }
  };

  const next = () => {
    if (index <= finalyData.length - 1) {
      index += 1;
      cardList.current.scrollTo({
        x: screenWidth * index,
        y: 0,
        animation: 'true',
      });
    }
    if (index >= finalyData.length) {
      index = finalyData.length - 1;
    }
  };
  const CardList: any = (props: {item: any}) => {
    const {item} = props;
    // console.log(item);

    switch (cardType) {
      case 'card':
        return (
          <View style={styles.cardCotin} key={item.id}>
            <View style={styles.cardBox}>
              {Object.keys(item).map(key => {
                return (
                  <View style={styles.card} key={item[key].id}>
                    <Card data={item[key]} onPress={() => {}} />
                  </View>
                );
              })}
            </View>
          </View>
        );
      case 'freecard':
        return (
          <View style={styles.cardCotin} key={item.id}>
            <View style={styles.cardBox}>
              {Object.keys(item).map(key => {
                return (
                  <View style={styles.card} key={item[key].id}>
                    <FreeCard data={item[key]} onPress={cardPress} />
                  </View>
                );
              })}
            </View>
          </View>
        );
      case 'cardGame':
        return (
          <View style={styles.cardCotin} key={item.id}>
            <View style={styles.cardBox}>
              {Object.keys(item).map(key => {
                return (
                  <View style={styles.card} key={item[key].id}>
                    <CardGame data={item[key]} onPress={() => {}} />
                  </View>
                );
              })}
            </View>
          </View>
        );
      case 'diceGame':
        return (
          <View style={styles.cardCotin} key={item.id}>
            <View style={styles.cardBox}>
              {Object.keys(item).map((key, index) => {
                return (
                  <View style={styles.card} key={item[key].id}>
                    <CardDice
                      data={item[key]}
                      index={index}
                      onPress={cardPress}
                    />
                  </View>
                );
              })}
            </View>
          </View>
        );
      case 'kerlala':
        return (
          <View style={styles.cardCotin} key={item.id}>
            <View style={styles.cardBox}>
              {Object.keys(item).map((key, index) => {
                return (
                  <View style={styles.card} key={item[key].id}>
                    <CardKerlala
                      data={item[key]}
                      key={index}
                      onPress={() => {
                        cardPress && cardPress(item[key]);
                      }}
                    />
                  </View>
                );
              })}
            </View>
          </View>
        );
      default:
        return <View key={item} />;
    }
  };

  const scroll = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    let x = e.nativeEvent.contentOffset.x;
    const screenWidth = Dimensions.get('window').width;

    index = Math.floor(x / screenWidth) + 1;
    if (index <= 1) {
      prevBtn.current.style.opacity = 0.5;
      nextBtn.current.style.opacity = 1;
    }
    if (index >= 1 && index < finalyData.length) {
      prevBtn.current.style.opacity = 1;
      nextBtn.current.style.opacity = 1;
      // return;
    }
    if (index === finalyData.length - 1) {
      prevBtn.current.style.opacity = 1;
      nextBtn.current.style.opacity = 0.5;
    }
  };

  return (
    <View onLayout={widthLayout}>
      <LinearGradient
        colors={['#FFF', 'rgba(255, 255, 255, 0.00)']}
        start={{x: 0, y: 0}}
        end={{x: 0, y: 1}}>
        <View
          style={{
            width: screenWidth,
            justifyContent: 'center',
            // backgroundColor: '#fff',
            marginBottom: 20,
          }}>
          <View style={styles.view}>
            {topBg ? (
              <Image
                style={styles.child}
                // contentFit="cover"
                source={require('./assets/floor.png')}
              />
            ) : (
              <View />
            )}
            <View
              style={[
                styles.frameParent,
                styles.frameFlexBox,
                {
                  display: 'flex',
                  width: ' 93.5%',
                  margin: 'auto',
                  justifyContent: 'space-between',
                },
              ]}>
              <View style={styles.frameFlexBox}>
                {icon === 'big' ? (
                  <View>
                    <LazyImage
                      imageUrl={iconScratchOff}
                      width={designToDp(24)}
                      height={designToDp(24)}
                    />
                  </View>
                ) : (
                  <View>
                    <Image
                      style={{width: 8, height: 8}}
                      // contentFit="cover"
                      source={require('./assets/little.webp')}
                    />
                  </View>
                )}
                <Text style={[styles.scratchOff, styles.viewAllTypo]}>
                  {title}
                </Text>
              </View>
              <View style={styles.frameFlexBox}>
                <LinearGradient
                  style={[styles.viewAllWrapper, styles.buttonWrapperFlexBox]}
                  locations={[0, 1]}
                  colors={['#f2f6ff', '#dfe3ed']}>
                  <Text
                    style={[styles.viewAll, styles.viewAllTypo]}
                    onPress={onPress}>
                    View all
                  </Text>
                </LinearGradient>
                <TouchableOpacity onPress={prev}>
                  <View
                    ref={prevBtn}
                    style={[
                      styles.buttonWrapper,
                      styles.buttonWrapperFlexBox,
                      {opacity: 0.5},
                    ]}>
                    <Image
                      style={[styles.buttonIcon, styles.buttonLayout]}
                      // contentFit="cover"
                      source={require('./assets/button.png')}
                    />
                  </View>
                </TouchableOpacity>

                <TouchableOpacity onPress={next}>
                  <LinearGradient
                    style={[styles.buttonContainer, styles.buttonLayout]}
                    locations={[0, 1]}
                    colors={['#f2f6ff', '#dfe3ed']}>
                    <Image
                      ref={nextBtn}
                      style={[styles.buttonIcon, styles.buttonLayout]}
                      // contentFit="cover"
                      source={require('./assets/button1.png')}
                    />
                  </LinearGradient>
                </TouchableOpacity>
              </View>
            </View>
          </View>
          <View>
            <ScrollView
              onScroll={e => {
                scroll(e);
              }}
              ref={cardList}
              scrollEventThrottle={16}
              overScrollMode={'always'}
              showsHorizontalScrollIndicator={false}
              pagingEnabled={true}
              horizontal={true}
              style={{width: screenWidth}}>
              {finalyData.map((item: any, index: any) => {
                return <CardList item={item} key={index} />;
              })}
              {/* <View style={styles.last}></View> */}
            </ScrollView>
          </View>
        </View>
      </LinearGradient>
    </View>
  );
};

const styles = StyleSheet.create({
  cardCotin: {
    width: '93.5%',
    margin: 'auto',
    marginTop: designToDp(-8),
    // marginLeft: designToDp(-1),
  },
  last: {
    // padding: 10,
    width: 50,
    height: '100%',
    boxSizing: 'border-box',
  },
  cardBox: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: Dimensions.get('window').width * 0.99,
    margin: 'auto',
    justifyContent: 'flex-start',
    marginTop: 10,
    marginLeft: designToDp(-8),
  },
  card: {
    borderRadius: 5,
    marginLeft: designToDp(7),
    overflow: 'hidden',
  },
  contentContainer: {
    paddingVertical: 20,
    // width: '100%',
  },
  frameFlexBox: {
    alignItems: 'center',
    // width: Dimensions.get('window').width * 0.55,
    flexDirection: 'row',
  },
  viewAllTypo: {
    textAlign: 'left',
    fontFamily: FontFamily.interBold,
    fontWeight: '700',
  },
  buttonWrapperFlexBox: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  buttonLayout: {
    width: 28,
    borderRadius: Border.br_9xs,
  },
  child: {
    left: 0,
    opacity: 0.5,
    top: 0,
    position: 'absolute',
    overflow: 'hidden',
    height: 48,
    width: '100%',
    borderRadius: Border.br_base,
  },
  frameChild: {
    left: 1,
    borderRadius: 3,
    width: 16,
    height: 21,
    backgroundColor: 'transparent',
    top: 0,
    position: 'absolute',
  },
  frameItem: {
    top: 2,
    left: 2,
    width: 11,
    height: 16,
    position: 'absolute',
  },
  image442Icon: {
    top: 6,
    left: 5,
    width: 18,
    height: 18,
    position: 'absolute',
  },
  rectangleParent: {
    width: 24,
    height: 24,
  },
  scratchOff: {
    fontSize: FontSize.size_sm,
    letterSpacing: 0,
    textTransform: 'capitalize',
    color: Color.color1,
    marginLeft: 8,
  },
  viewAll: {
    fontSize: FontSize.size_xs,
    color: Color.color,
  },
  viewAllWrapper: {
    shadowColor: '#adb3c8',
    padding: Padding.p_xs,
    height: 28,
    shadowOpacity: 1,
    elevation: 0,
    shadowRadius: 0,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    justifyContent: 'center',
    backgroundColor: 'transparent',
    borderRadius: Border.br_9xs,
  },
  buttonIcon: {
    height: 29,
    overflow: 'hidden',
  },
  buttonWrapper: {
    marginLeft: 8,
  },
  buttonContainer: {
    shadowColor: 'rgba(148, 157, 183, 0.5)',
    borderStyle: 'solid',
    borderColor: '#fff',
    borderTopWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    padding: Padding.p_xs,
    height: 28,
    shadowOpacity: 1,
    elevation: 0,
    shadowRadius: 0,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    backgroundColor: 'transparent',
    marginLeft: 8,
  },
  frameParent: {
    display: 'flex',
    // top: 12,
    // left: 12,
    // width: 351,
    justifyContent: 'space-evenly',
    // position: 'absolute',
  },
  view: {
    margin: 'auto',
    // backgroundColor: '#f6f7fa',
    overflow: 'hidden',
    height: 48,
    width: Dimensions.get('window').width,
    // borderRadius: Border.br_base,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
});

export default Screen1;
