import * as React from 'react';
import {
  Text,
  StyleSheet,
  View,
  ImageBackground,
  TouchableOpacity,
} from 'react-native';
import {
  FontSize,
  FontFamily,
  Color,
  Border,
  Padding,
} from '../card.common.styles';
import {designToDp} from '@utils';
interface CardDiceProps {
  data: {
    id: any;
    iconUrl: string;
  };
  index: number;
  onPress?: (data: {id: any; iconUrl: string; index: number}) => void;
}

const CardDice = (props: CardDiceProps) => {
  const {data, index, onPress} = props;

  return (
    <TouchableOpacity
      onPress={() => {
        onPress &&
          onPress({
            ...data,
            index,
          });
      }}>
      <View style={[styles.cardDice, styles.cardLayout]}>
        <View style={[styles.cardDiceInner, styles.cardLayout]}>
          <ImageBackground source={{uri: data?.iconUrl}} style={styles.backImg}>
            <View style={styles.playNowWrapper}>
              <Text style={styles.playNow}>Play now</Text>
            </View>
          </ImageBackground>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  backImg: {
    flex: 1,
    resizeMode: 'cover',
  },
  cardLayout: {
    position: 'relative',
    overflow: 'hidden',
    height: designToDp(145),
    width: designToDp(172),
  },
  playNow: {
    fontSize: FontSize.size_xs,
    letterSpacing: 0,
    lineHeight: 11,
    textTransform: 'capitalize',
    fontWeight: '700',
    fontFamily: FontFamily.interBold,
    color: Color.white,
    textAlign: 'left',
  },
  playNowWrapper: {
    // marginLeft: -76,
    // bottom: ,
    top: designToDp(106),
    left: designToDp(10),
    borderRadius: Border.br_9xs,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    shadowColor: 'rgba(0, 0, 0, 0.1)',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 4,
    elevation: 4,
    shadowOpacity: 1,
    borderStyle: 'solid',
    borderColor: 'rgba(255, 255, 255, 0.2)',
    borderTopWidth: 1,
    width: designToDp(152),
    flexDirection: 'row',
    paddingHorizontal: 12,
    paddingVertical: Padding.p_5xs,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  cardDiceInner: {
    top: 0,
    left: 0,
    backgroundColor: Color.whitesmoke_100,
    position: 'absolute',
  },
  cardDice: {
    borderRadius: Border.br_5xs,
  },
});

export default CardDice;
